.kube-iso {
    background-image: url("../img/kube_iso_spritesheet.png");
    background-repeat: no-repeat;
    display: block;
    width: 36px;
    height: 42px;
}

.kube-iso-01 {
    background-position: 0 0;
}

.kube-iso-02 {
    background-position: -36px 0;
}

.kube-iso-03 {
    background-position: -72px 0;
}

.kube-iso-04 {
    background-position: -108px 0;
}

.kube-iso-05 {
    background-position: -144px 0;
}

.kube-iso-06 {
    background-position: -180px 0;
}

.kube-iso-07 {
    background-position: -216px 0;
}

.kube-iso-08 {
    background-position: -252px 0;
}

.kube-iso-09 {
    background-position: -288px 0;
}

.kube-iso-10 {
    background-position: 0 -42px;
}

.kube-iso-11 {
    background-position: -36px -42px;
}

.kube-iso-12 {
    background-position: -72px -42px;
}

.kube-iso-13 {
    background-position: -108px -42px;
}

.kube-iso-14 {
    background-position: -144px -42px;
}

.kube-iso-15 {
    background-position: -180px -42px;
}

.kube-iso-16 {
    background-position: -216px -42px;
}

.kube-iso-17 {
    background-position: -252px -42px;
}

.kube-iso-18 {
    background-position: -288px -42px;
}

.kube-iso-19 {
    background-position: 0 -84px;
}

.kube-iso-20 {
    background-position: -36px -84px;
}

.kube-iso-21 {
    background-position: -72px -84px;
}

.kube-iso-22 {
    background-position: -108px -84px;
}

.kube-iso-23 {
    background-position: -144px -84px;
}

.kube-iso-24 {
    background-position: -180px -84px;
}

.kube-iso-25 {
    background-position: -216px -84px;
}

.kube-iso-26 {
    background-position: -252px -84px;
}

.kube-iso-27 {
    background-position: -288px -84px;
}

.kube-iso-28 {
    background-position: 0 -126px;
}

.kube-iso-29 {
    background-position: -36px -126px;
}

.kube-iso-30 {
    background-position: -72px -126px;
}

.kube-iso-31 {
    background-position: -108px -126px;
}

.kube-iso-32 {
    background-position: -144px -126px;
}

.kube-iso-33 {
    background-position: -180px -126px;
}

.kube-iso-34 {
    background-position: -216px -126px;
}

.kube-iso-35 {
    background-position: -252px -126px;
}

.kube-iso-36 {
    background-position: -288px -126px;
}

.kube-iso-37 {
    background-position: 0 -168px;
}

.kube-iso-38 {
    background-position: -36px -168px;
}

.kube-iso-39 {
    background-position: -72px -168px;
}

.kube-iso-40 {
    background-position: -108px -168px;
}

.kube-iso-41 {
    background-position: -144px -168px;
}

.kube-iso-42 {
    background-position: -180px -168px;
}

.kube-iso-43 {
    background-position: -216px -168px;
}

.kube-iso-44 {
    background-position: -252px -168px;
}

.kube-iso-45 {
    background-position: -288px -168px;
}

.kube-iso-46 {
    background-position: 0 -210px;
}

.kube-iso-47 {
    background-position: -36px -210px;
}

.kube-iso-48 {
    background-position: -72px -210px;
}

.kube-iso-49 {
    background-position: -108px -210px;
}

.kube-iso-50 {
    background-position: -144px -210px;
}

.kube-iso-51 {
    background-position: -180px -210px;
}

.kube-iso-52 {
    background-position: -216px -210px;
}

.kube-iso-53 {
    background-position: -252px -210px;
}

.kube-iso-54 {
    background-position: -288px -210px;
}

.kube-iso-55 {
    background-position: 0 -252px;
}

.kube-iso-56 {
    background-position: -36px -252px;
}

.kube-iso-57 {
    background-position: -72px -252px;
}

.kube-iso-58 {
    background-position: -108px -252px;
}

.kube-iso-59 {
    background-position: -144px -252px;
}

.kube-iso-60 {
    background-position: -180px -252px;
}

.kube-iso-61 {
    background-position: -216px -252px;
}

.kube-iso-62 {
    background-position: -252px -252px;
}

.kube-iso-63 {
    background-position: -288px -252px;
}

.kube-iso-64 {
    background-position: 0 -294px;
}

.kube-iso-65 {
    background-position: -36px -294px;
}

.kube-iso-66 {
    background-position: -72px -294px;
}

.kube-iso-67 {
    background-position: -108px -294px;
}

.kube-iso-68 {
    background-position: -144px -294px;
}

.kube-iso-69 {
    background-position: -180px -294px;
}

.kube-iso-70 {
    background-position: -216px -294px;
}

.kube-iso-71 {
    background-position: -252px -294px;
}

.kube-iso-72 {
    background-position: -288px -294px;
}

.kube-iso-73 {
    background-position: -324px 0;
}

.kube-iso-74 {
    background-position: -324px -42px;
}

.kube-iso-75 {
    background-position: -324px -84px;
}

.kube-iso-76 {
    background-position: -324px -126px;
}

.kube-iso-77 {
    background-position: -324px -168px;
}
